<template>
  <gl-modal
    v-bind="$attrs"
    class="gl-modal__for-image"
    max-height-form="70vh"
    submit-title="Close"
    ticker="vw"
    title=""
    width="95"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('confirm')"
  >
    <div
      v-if="data && data.length"
      class="flex align-center modal-image-container"
    >
      <div
        v-if="activeImageIndex > 0"
        class="mr-3 pointer image-arrow-back"
        @click.stop="prev"
      >
        <gl-icon
          :height="24"
          name="double-arrow-right"
          style="min-width: 24px"
          :width="24"
        />
      </div>

      <div
        class="flex-1 modal-image-container modal-image-container-image overflow-auto"
      >
        <embed
          v-if="
            data[activeImageIndex].icon &&
              data[activeImageIndex].icon.includes('data:application/pdf;')
          "
          class="fullwidth full-height"
          :src="data[activeImageIndex].icon"
          type="application/pdf"
        >

        <GlImageViewer
          v-else
          :src="data[activeImageIndex].icon"
        />
      </div>

      <div
        v-if="data && data.length - 1 > activeImageIndex"
        class="ml-3 pointer"
        @click.stop="next"
      >
        <gl-icon
          :height="24"
          name="double-arrow-right"
          style="min-width: 24px"
          :width="24"
        />
      </div>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlImageViewer from '@/components/gl-image-viewer'
import GlIcon from '@/components/gl-icon'

export default {
  components: {
    GlModal,
    GlImageViewer,
    GlIcon,
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    activeImageIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    prev() {
      if (this.activeImageIndex > 0) {
        this.$emit('setActiveImageIndex', this.activeImageIndex - 1)
      }
    },
    next() {
      if (this.data && this.data.length - 1 > this.activeImageIndex) {
        this.$emit('setActiveImageIndex', this.activeImageIndex + 1)
      }
    },
  },
}
</script>

<style>
.modal-image-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
}
.modal-image-container-image {
  align-items: start;
}
.modal-image-container img {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 9px;
}
.image-arrow-back {
  transform: rotate(180deg);
}
</style>
