<template>
  <img
    :src="src"
    :width="width"
  >
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
  },
}
</script>
