<template>
  <GlPageWrap
    class="pp-page__entity"
    hide-title-on-mobile
    :title="
      getFirstRouteName($route) === 'entity-management'
        ? 'Entity Management'
        : 'Entity Explorer'
    "
  >
    <template #actions>
      <EntityActions
        @clickDelete="clickDelete()"
        @openConfirmModal="openConfirmModal()"
      />
    </template>
    <EntityHeader />
    <div class="pp-entity__content">
      <router-view
        ref="entityFormWrapper"
        class="entity-view-wrapper"
        :countries-list="countriesList"
        :languages-list="languagesList"
        :tags-list="tagsList"
        :types-list="typesList"
        @closeConfirmPublishModal="showConfirmPublishModal = false"
        @loadingModal="loadingModalToggle"
        @setImageModalData="setImageModalData"
        @showViewImageModal="showViewImageModal = true"
      />
      <div
        v-if="$route.name === 'entity-management' || $route.name === 'entity-explorer'"
        class="pp-entity__content-empty"
      >
        <div class="pp-entity__content-empty-icon">
          <gl-icon
            class="mb-1"
            :height="24"
            name="find-dis"
            :width="24"
          />
        </div>
        <div class="pp-entity__content-empty-text">
          Type entity name in the search bar above to start exploring.
        </div>
      </div>
    </div>
    <GlConfirmModal
      v-if="showConfirmPublishModal"
      v-model="showConfirmPublishModal"
      :loading="loadingModal"
      title="Publish"
      @close="showConfirmPublishModal = false"
      @confirm="publishConfirmed()"
    >
      <template #main-slot>
        {{
          $route.name === 'create-entity-management'
            ? 'Do you confirm adding a new entity?'
            : 'Do you confirm edit entity?'
        }}
      </template>
    </GlConfirmModal>
    <ViewImageModal
      v-if="showViewImageModal"
      v-model="showViewImageModal"
      :active-image-index="activeImageIndex"
      :data="imageList"
      ok-only
      @confirm="confirmImageModal"
      @setActiveImageIndex="setActiveImageIndex"
    />
    <GlConfirmModal
      v-if="showConfirmDeleteModal"
      v-model="showConfirmDeleteModal"
      :loading="loadingDeleteModal"
      title="Are you sure?"
      @close="showConfirmDeleteModal = false"
      @confirm="deleteEntityById()"
    >
      <template #main-slot>
        Delete <b>{{ getActiveEntity.name }}</b>?
      </template>
    </GlConfirmModal>
  </GlPageWrap>
</template>

<script>
// Components
import GlPageWrap from '@/components/layout/gl-page-wrap'
import GlConfirmModal from '@/components/gl-confirm-modal'
import ViewImageModal from '@/pages/entity/modals/ViewImageModal'
import EntityHeader from '@/pages/entity/components/EntityHeader'
import EntityActions from '@/pages/entity/components/EntityActions'
import GlIcon from '@/components/gl-icon'

// Utils
import { getFirstRouteName } from '@/utils/get-route-name'
import { isDefaultType } from '@/utils/find-default-tags-types'

// Models
import { localCountriesList, allCountriesListV2, allCountriesListV3 } from '@/pages/entity/models/countries-list'

// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    GlPageWrap,
    GlConfirmModal,
    ViewImageModal,
    EntityHeader,
    EntityActions,
    GlIcon,
  },
  data() {
    return {
      showConfirmPublishModal: false,
      showViewImageModal: false,
      languagesList: [],
      countriesList: [],
      typesList: [],
      tagsList: [],
      imageList: [],
      activeImageIndex: 0,
      loadingModal: false,
      search: '',
      showConfirmDeleteModal: false,
      loadingDeleteModal: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('entity', ['getActiveEntity']),
    localCountriesList() {
      return localCountriesList
    },
  },
  async created() {
    allCountriesListV2.push(...localCountriesList)
    this.countriesList = allCountriesListV2
    this.languagesList = allCountriesListV3

    this.getTypes({ count: 10000 }).then(
      ({ data: { typeData } }) =>
        (this.typesList = typeData
          .filter(type => !isDefaultType(type.name))
          .map(type => {
            return { ...type }
          })),
    )
    this.getTags({ count: 10000 }).then(
      ({ data: { tagData } }) =>
        (this.tagsList = tagData.map(tag => ({
          ...tag,
          tagId: tag._id,
        }))),
    )
  },

  methods: {
    ...mapActions('entity', ['getCountries', 'getLanguages', 'deleteEntity']),
    ...mapActions('tagging', ['getTags']),
    ...mapActions('types', ['getTypes']),
    getFirstRouteName,
    isDefaultType,
    publishConfirmed() {
      this.$refs.entityFormWrapper.onSubmit(
        this.$refs.entityFormWrapper.$refs.entityForm.errors,
      )
    },
    loadingModalToggle(e) {
      this.loadingModal = e
    },
    setImageModalData(data, i) {
      this.imageList = data
      this.activeImageIndex = i
    },
    setActiveImageIndex(i) {
      this.activeImageIndex = i
    },
    confirmImageModal() {
      this.showViewImageModal = false
      this.imageList = []
      this.activeImageIndex = 0
    },
    async openConfirmModal() {
      const isValid =
        await this.$refs.entityFormWrapper.$refs.entityForm.validate()
      if (isValid) {
        this.showConfirmPublishModal = true
      } else {
        this.$refs.entityFormWrapper.currentStep = 0
      }
    },
    clickDelete() {
      this.showConfirmDeleteModal = true
    },
    async deleteEntityById() {
      this.loadingDeleteModal = true
      this.deleteEntity(this.getActiveEntity.id).then(() => {
        this.loadingDeleteModal = false
        this.showConfirmDeleteModal = false
        this.$toasted.global.success({
          message: `${this.getActiveEntity.name || 'Entity'} was deleted`,
        })
        this.$router.push({
          name: 'entity-explorer',
        })
      })
    },
  },
}
</script>

<style>
.entity-screensot {
  width: 87px;
  max-width: 87px;
  height: 50px;
  border-radius: 3px;
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
  cursor: pointer;
}
.entity-screensot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.entity-screensot embed {
  width: 250px;
}
.entity-screensot-wrapper {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  min-width: 0;
  margin-bottom: 16px;
}
.entity-screensot-full-view-icon {
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 2px;
}


.pp-page__entity {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}
.pp-page__entity .gl-button {
  height: 32px;
  font-size: 16px;
  text-transform: none;
}
.pp-entity__content {
  flex: 1 0 auto;
  background-color: var(--white);
  border-radius: 12px;
}
.pp-entity__content-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  color: var(--dark-grey);
  text-align: center;
}

@media (max-width: 1023px) {
  .pp-page__entity .gl-modal__container {
    max-height: 70vh !important;
    overflow: auto;
  }
  .pp-page__entity .gl-modal__container::-webkit-scrollbar {
    width: 8px;
  }
  .pp-page__entity .gl-modal__container .form-content-wrapper {
    max-height: 100% !important;
    overflow: visible;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
  select, textarea, input {
    font-size: 14px !important;
  }
}

@media (max-width: 767px) {
  .pp-page__entity {
    padding-top: 56px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }
  .page-wrapper__header {
    min-width: 100%;
    margin-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding: 10px 16px 0 16px;
    background-color: var(--white);
  }
  .pp-entity__content {
    flex: 1 0 auto;
    background-color: transparent;
    border-radius: 0 0 12px 12px;
  }
}
</style>
